<template>
  <div>
    <b-card no-body class="mb-0 p-2">
      <LoadingComponent v-if="loading" />
      <div v-else>
        <b-row>
          <b-col md="6" class="mb-1">
            <b-form-group label-for="basicInput">
              <h4>عنوان دسته بندی</h4>
              <b-form-input name="title" id="title" v-model="formData.title" placeholder="عنوان دسته بندی" />
            </b-form-group>
          </b-col>
          <b-col md="6" class="mb-1">
            <b-form-group label-for="basicInput">
              <h4>اسلاگ</h4>
              <b-form-input name="slug" id="slug" v-model="formData.slug" placeholder="اسلاگ" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-1">
            <h4>توضیحات کامل</h4>
            <tiptap :order="1" v-model="formData.body" />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-1">
            <h4>توضیحات</h4>
            <b-form-textarea name="description" id="textarea-no-resize"
              placeholder="توضیحات مختصری برای محصول خود وارد کنید، این توضیحات باید حداکثر 150 کاراکتر باشد" rows="3"
              v-model="formData.description" no-resize />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-1">
            <b-button class="my-2 d-flex justify-content-center align-items-center" variant="primary"
              @click="updateCategory()">
              ثبت
            </b-button>
          </b-col>
        </b-row>
        <CategoriesTreeVue :nodes="root" @onClick="nodeWasClicked()" />
      </div>
    </b-card>
  </div>
</template>
  
<script>
import CategoriesTreeVue from "../../components/CategoriesTreeVue.vue";
import vSelect from "vue-select";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BButton,
} from "bootstrap-vue";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import router from "@/router";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from "../../components/event-bus";
import LoadingComponent from "../../components/LoadingComponent.vue";
import Tiptap from '@/components/Tiptap.vue'

export default {
  components: {
    Tiptap,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,
    CategoriesTreeVue,
    Prism,
    ToastificationContent,
    EventBus,
    LoadingComponent,
    vSelect,
  },
  data() {
    return {
      loading: true,
      menuId: "",
      root: [
        {
          id: null,
          title: "دسته بندی",
          childs: [],
        },
      ],
      formData: {
        title: "",
        slug: "",
        parent_id: null,
        description: "",
        body: ``,
        thumbnail_id: null,
        is_special: 0,
        status: 1,
      },
    };
  },
  watch: {
    root: {
      deep: true,
      handler: (newRoot) => {
        // console.log(newRoot);
      },
    },
  },
  methods: {
    nodeWasClicked(node) {
      // alert(node.label);
    },

    getCategory() {
      this.root[0].title = "";
      this.root[0].childs = [];
      this.loading = true;
      axios
        .get(`/api/v1/admin/categories/${router.currentRoute.params.id}/view`)
        .then((response) => {
          this.categoryTray = response.data.data;

          this.formData.title = this.categoryTray.title;
          this.formData.slug = this.categoryTray.slug;
          this.formData.description = this.categoryTray.description;
          this.formData.body = this.categoryTray.body;

          this.root[0].id = response.data.data.id;
          this.root[0].title = response.data.data.title;
          this.root[0].childs = response.data.data.childs;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    updateCategory() {
      if (this.formData.title != "" && this.formData.slug != "") {
        axios
          .post(
            `/api/v1/admin/categories/${router.currentRoute.params.id}/update`,
            this.formData
          )
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "دسته بندی آپدیت شد",
                icon: "CheckIcon",
                text: "دسته بندی با موفیقت آپدیت شد",
                variant: "success",
              },
            });
            router.push("/categories")
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "اخطار",
                icon: "XOctagonIcon",
                text: "مشکلی پیش آمده!",
                variant: "danger",
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "لطفا فیلد ها را پر کنید",
            variant: "info",
            icon: "XOctagonIcon",
          },
        });
      }
    },
  },
  mounted() {
    this.getCategory();

    EventBus.$on("categoryItemChanged", this.getCategory);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>