<template>
  <div>
    <div>
      <div
        v-for="node in nodes"
        :key="node.id"
        :style="{ 'margin-right': `${depth * 10}px !important` }"
        class="node"
      >
        <span
          v-if="node.childs && node.childs.length"
          class="type"
          @click="nodeClicked(node)"
        >
          <feather-icon v-if="isExpanded(node)" icon="ChevronUpIcon" />
          <feather-icon v-else icon="ChevronDownIcon" />
        </span>

        <span class="type">
          <feather-icon
            v-if="node.childs && node.childs.length"
            icon="FolderIcon"
          />
          <feather-icon v-else icon="FileIcon" />
          <feather-icon @click="plusFunction(node)" icon="PlusIcon" />
          <feather-icon
            v-if="node.title != 'منو'"
            @click="minusFunction(node)"
            icon="MinusIcon"
          />
        </span>
        <span :style="getStyle(node)">{{ node.title }} </span>
        <TreeBrowser
          v-if="isExpanded(node) && node.childs"
          :nodes="node.childs"
          :depth="depth + 1"
          @onClick="(node) => $emit('onClick', node)"
        />
      </div>
    </div>
    <!-- add to menu items modal -->
    <div id="modal-template" v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <b-card class="modal-container">
              <div class="modal-header px-0 pt-0">
                <h3 class="mb-0">
                  {{ isAddCategory ? "افزودن به منو" : "حذف آیتم" }}
                </h3>
              </div>
              <div v-if="isAddCategory" class="modal-body px-0 pb-0">
                <b-form>
                  <b-form-group>
                    <label for="title">نام:</label>
                    <b-form-input
                      id="title"
                      v-model="formData.title"
                      type="text"
                      placeholder="نام"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="slug">اسلاگ:</label>
                    <b-form-input
                      id="slug"
                      v-model="formData.slug"
                      type="text"
                      placeholder="اسلاگ"
                    />
                  </b-form-group>
                </b-form>
              </div>
              <div
                class="modal-footer"
                :class="isAddCategory ? '' : 'mt-1 border-0'"
              >
                <b-button
                  class="mt-1"
                  variant="outline-primary"
                  @click.prevent="closeModal()"
                >
                  <span class="text-nowrap">بستن</span>
                </b-button>
                <b-button
                  class="mt-1"
                  v-if="isAddCategory"
                  variant="primary"
                  @click.prevent="addToCategory()"
                >
                  <span class="text-nowrap">ثبت</span>
                </b-button>
                <b-button
                  class="mt-1"
                  v-if="isRemoveCategory"
                  variant="primary"
                  @click.prevent="removeFromCategory()"
                >
                  <span class="text-nowrap">حذف</span>
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script>
import draggable from "vuedraggable";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from "./event-bus";

export default {
  name: "TreeBrowser",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ToastificationContent,
    draggable,
  },
  props: {
    nodes: Array,
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      nodeId: "",
      expanded: [],
      showModal: false,
      isAddCategory: false,
      isRemoveCategory: false,
      formData: {
        title: "",
        body: null,
        slug: "",
        post_id: null,
        parent_id: null,
        description: null,
      },
    };
  },
  methods: {
    isExpanded(node) {
      return this.expanded.indexOf(node) !== -1;
    },
    nodeClicked(node) {
      if (!this.isExpanded(node)) {
        this.expanded.push(node);
      } else {
        this.expanded.splice(this.expanded.indexOf(node));
      }
    },
    getStyle(node) {
      let color = "#3B4F93";
      if (!node.childs || !node.childs.length) {
        color = "#636363";
      }
      return {
        color,
      };
    },
    plusFunction(node) {
      this.nodeId = null;

      if (node.id) {
        this.nodeId = node.id;
      } else {
        this.nodeId = null;
      }

      this.showModal = true;
      this.isAddCategory = true;
    },
    addToCategory() {
      this.formData.parent_id = this.nodeId;

      axios
        .post("/api/v1/admin/categories/create", this.formData)
        .then((response) => {
          this.categoryItemChanged();

          this.formData.title = "";
          this.formData.slug = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "آیتم جدید ساخته شد",
              icon: "XOctagonIcon",
              text: "آیتم جدید با موفقیت ساخته شد",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });

      // this.expanded.push(node);

      this.showModal = false;
      this.isAddCategory = false;
    },
    minusFunction(node) {
      this.nodeId = null;

      if (node.id) {
        this.nodeId = node.id;
      } else {
        this.nodeId = null;
      }

      this.showModal = true;
      this.isRemoveCategory = true;
    },
    removeFromCategory() {
      axios
        .post(`/api/v1/admin/categories/${this.nodeId}/delete`)
        .then((response) => {
          this.categoryItemChanged();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "آیتم مورد نظر حذف شد",
              icon: "XOctagonIcon",
              text: "آیتم مورد نظر با موفقیت حذف شد",
              variant: "info",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });

      // this.expanded.push(node);

      this.isRemoveCategory = false;
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
      this.isRemoveCategory = false;
      this.isAddCategory = false;
      this.formData.title = ''
      this.formData.slug = ''
    },
    categoryItemChanged() {
      // event bus
      EventBus.$emit("categoryItemChanged");
    },
  },
};
</script>
<style>
.node {
  text-align: left;
  font-size: 18px;
}

.type {
  margin-right: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 16;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #3B4F93;
}

.modal-default-button {
  float: right;
  border: none;
  border-radius: 2px;
  padding: 10px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
  